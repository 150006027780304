
export function isAuthorized (user, path) {

    const requiredPermission = "ui/" + path

    if (user?.permissions?.includes(requiredPermission)) {
        return true;
    } else if (user?.permissions !== undefined && !user?.permissions?.includes(requiredPermission)) {
        console.log("Not authorized for " + requiredPermission)
        console.log(user)
    }

    return false;
}
